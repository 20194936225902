.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}
.free-btn-col {
  margin-top: 1.6rem !important;
}
.free-btn {
  padding: 1rem !important;
  padding-top: 1.6rem !important;
  text-decoration: none;
  -webkit-box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: #216fec !important;
  color: #dcdcdc !important;
  outline: none !important;
  border: 0 !important;
  span {
    font-size: 2rem;
    padding-left: 0.6rem;
  }
  img {
    height: 4rem;
    transform: translateY(-0.4rem);
  }

  font-family: Poppins-SemiBold !important;
  &:hover {
    background-color: #dcdcdc !important;
    color: #216fec !important;
  }
}

.go-btn {
  /* padding: 1rem !important; */
  padding-bottom: 0.3rem !important;
  text-decoration: none;
  -webkit-box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 6px 18px 18px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background-color: #216fec !important;
  color: #dcdcdc !important;
  outline: none !important;
  border: 0 !important;
  span {
    font-size: 1rem;
    padding-left: 0.6rem;
  }
  img {
    height: 2rem;
    transform: translateY(rem);
  }

  font-family: Poppins-SemiBold !important;
  &:hover {
    background-color: #dcdcdc !important;
    color: #216fec !important;
  }
}

.links {
  margin-top: 3rem !important;
  color: white;
  font-family: Poppins-Medium;
  font-size: 1.5rem;
}

.contact-us {
  margin: 3rem 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
