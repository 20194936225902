@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("../fonts/Poppins/Poppins-BlackItalic.ttf");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("../fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-LightItalic";
  src: url("../fonts/Poppins/Poppins-LightItalic.ttf");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("../fonts/Poppins/Poppins-MediumItalic.ttf");
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("../fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("../fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("../fonts/Poppins/Poppins-ThinItalic.ttf");
}

@font-face {
  font-family: "Barlow-Regular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf");
}
@font-face {
  font-family: "Barlow-Bold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf");
}
@font-face {
  font-family: "Barlow-Medium";
  src: url("../fonts/Barlow/Barlow-Medium.ttf");
}
@font-face {
  font-family: "Barlow-SemiBold";
  src: url("../fonts/Barlow/Barlow-SemiBold.ttf");
}
@font-face {
  font-family: "Barlow-Black";
  src: url("../fonts/Barlow/Barlow-Black.ttf");
}
/* @font-face {
  font-family: "Barlow-BlackItalic";
  src: url("./fonts/Barlow/Barlow-BlackItalic.ttf");
} */
@font-face {
  font-family: "Barlow-ExtraBold";
  src: url("../fonts/Barlow/Barlow-ExtraBold.ttf");
}
@font-face {
  font-family: "Barlow-ExtraBoldItalic";
  src: url("../fonts/Barlow/Barlow-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: "Barlow-ExtraLight";
  src: url("../fonts/Barlow/Barlow-ExtraLight.ttf");
}
@font-face {
  font-family: "Barlow-ExtraLightItalic";
  src: url("../fonts/Barlow/Barlow-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Barlow-Light";
  src: url("../fonts/Barlow/Barlow-Light.ttf");
}
@font-face {
  font-family: "Barlow-LightItalic";
  src: url("../fonts/Barlow/Barlow-LightItalic.ttf");
}
@font-face {
  font-family: "Barlow-MediumItalic";
  src: url("../fonts/Barlow/Barlow-MediumItalic.ttf");
}
@font-face {
  font-family: "Barlow-SemiBoldItalic";
  src: url("../fonts/Barlow/Barlow-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Barlow-Thin";
  src: url("../fonts/Barlow/Barlow-Thin.ttf");
}
@font-face {
  font-family: "Barlow-ThinItalic";
  src: url("../fonts/Barlow/Barlow-ThinItalic.ttf");
}
